require('./bootstrap');
import Splide from '@splidejs/splide';
import tippy from 'tippy.js';
import Toasted from 'vue-toasted';
import { getCookie, setCookie } from './utils/functions';
import Store from './utils/store';

let activeMarkets = [];

function vimeoPlay() {
    const iframe = $('#vimeo_video_id iframe');
    if (iframe.length) {
        const player = new Vimeo.Player(iframe[0]);
        $('#play_vimeo_button').css('display', 'none');
        $('.preview-course-overlay').css('display', 'none');
        $('.preview-course-text').css('display', 'none');
        player.play();
    }
}

function setActiveMarketsHeight() {
    if ($(window).width() <= 991) {
        $('.lang-block').css({ paddingTop: `${$('#inner__filter-market')[0].scrollHeight}px` });
    } else {
        $('.lang-block').css({ paddingTop: 0 });
    }
}

function toggleFilter() {
    const filter = $('.new__filter');
    const filterJs = document.getElementsByClassName('new__filter')[0];
    const expandFilterBar = $('.expand_filter');

    $('#toggle_filter, #toggle_filter_mobile, .open-filters').on('click', (e) => {
        e.preventDefault();
        const zeroed = filter.attr('style') === 'max-height: 0px; height: 0px;';
        if (!filter.hasClass('active') && zeroed) {
            filter.removeAttr('style');
        }
        filter.toggleClass('active');
        expandFilterBar.toggleClass('active');

        if ($(window).width() <= 991) {
            if (filter.css('max-height') === '0px') {
                filter.css({ 'max-height': filterJs.scrollHeight, height: filterJs.scrollHeight });
            } else {
                filter.css({ 'max-height': 0, height: 0 });
            }
        }
        if (filter.hasClass('active')) {
            const newStartLearning = $('.new__start-learning');
            let offset = 150;
            if (newStartLearning.length > 0) {
                offset = 175;
            }
            $([document.documentElement, document.body]).animate({
                scrollTop: filter.offset().top - offset,
            }, 500);
        }
        setTimeout(() => {
            setActiveMarketsHeight();
        }, 50);
    });

    $('#close-filter, #close-filter-mobile').on('click', (e) => {
        e.preventDefault();
        if (filter.hasClass('active')) {
            filter.removeClass('active');
        }

        if (expandFilterBar.hasClass('active')) {
            expandFilterBar.removeClass('active');
        }

        if ($(window).width() <= 991) {
            if (filter.css('max-height') === '0px') {
                filter.css({ 'max-height': filterJs.scrollHeight, height: filterJs.scrollHeight });
            } else {
                filter.css({ 'max-height': 0, height: 0 });
            }
        }
    });
}

function initVimeoPlay() {
    $('#play_vimeo_button').click((e) => {
        e.preventDefault();
        vimeoPlay();
    });
}

function clearMarketLabels() {
    const label = $('#market-checkboxes label');
    label.removeClass('active');
    label.show();
}

function eraseInput() {
    const btn = $('#remove_market');

    btn.on('click', (e) => {
        e.preventDefault();
        btn.parent().find('input').val('');
        clearMarketLabels();
        $('.toggle-markets').removeClass('focused');
    });
}

function searchMarketCheckboxes() {
    const marketsInput = $('.toggle-markets input');

    marketsInput.on('input', () => {
        const input = marketsInput.val().toLowerCase();
        if (input.length >= 1) {
            $('.toggle-markets').addClass('focused');
            $('#market-checkboxes label').removeClass('active');
            $(`#market-checkboxes label span.hidden:contains(${input})`).parent().addClass('active');
            $('#market-checkboxes label.active').show();
            $('#market-checkboxes label:not(.active)').hide();
        } else {
            clearMarketLabels();
            $('.toggle-markets').removeClass('focused');
        }
    });
}

function setBannerHeight(height = null) {
    const startLearning = $('.new__start-learning.with-filters');
    const activeFiltersBlock = $('.active_filters:not(.hidden)');
    const newInnerFilter = $('.new__inner-filter:not(.journal)');

    if (newInnerFilter) {
        newInnerFilter.css({ marginBottom: activeFiltersBlock.innerHeight() + 30 });
        $(window).resize(() => {
            newInnerFilter.css({ marginBottom: activeFiltersBlock.innerHeight() + 30 });
        });
    }

    if (startLearning && activeFiltersBlock && !height) {
        startLearning.css({ marginBottom: activeFiltersBlock.innerHeight() + 45 });
        $(window).resize(() => {
            startLearning.css({ marginBottom: activeFiltersBlock.innerHeight() + 45 });
        });
    }

    // eslint-disable-next-line eqeqeq
    if (height !== null) {
        startLearning.css({ marginBottom: height });
        if (newInnerFilter) {
            newInnerFilter.css({ marginBottom: height });
        }
    }
}

function resetActiveFilter() {
    const activeFilters = document.querySelectorAll('.active-filter:not(.hidden)');
    const filterForm = $('#filter-form');
    let clickEvents = 0;
    for (let i = 0; i < activeFilters.length; i++) {
        // eslint-disable-next-line no-loop-func
        activeFilters[i].addEventListener('click', (e) => {
            e.preventDefault();
            const slug = activeFilters[i].getAttribute('id');
            const inputs = document.getElementsByClassName(`flt_${slug.split('-').pop()}`);
            for (let j = 0; j < inputs.length; j++) {
                if (inputs[j].type === 'checkbox') {
                    inputs[j].checked = false;
                } else {
                    inputs[j].value = '';
                }
            }
            if (slug === 'filter-topic') {
                inputs[0].setAttribute('data-clear', '1');
            }
            activeFilters[i].classList.add('hidden');
            clickEvents++;
            setBannerHeight();
            if (clickEvents === activeFilters.length) {
                setBannerHeight(80);
                document.querySelector('.active_filters').classList.add('hidden');
                document.querySelector('.new__start-learning').classList.remove('with-filters');
            }
            filterForm.submit();
        });
    }
}

function displayActiveMarkets() {
    const filterList = document.querySelectorAll('#filter-market, #inner__filter-market');
    const activeFilters = document.querySelector('.active_filters');

    for (let i = 0; i < filterList.length; i++) {
        const children = filterList[i].querySelectorAll('span');
        if (children.length > 0) {
            for (let k = 0; k < children.length; k++) {
                children[k].remove();
            }
        }
        for (let j = 0; j < activeMarkets.length; j++) {
            filterList[i].classList.remove('hidden');
            resetActiveFilter();
            const child = document.createElement('SPAN');
            if (activeMarkets.length > 1) {
                if (j === activeMarkets.length - 1) {
                    child.innerHTML = activeMarkets[j].title;
                    filterList[i].appendChild(child);
                } else {
                    child.innerHTML = `${activeMarkets[j].title}<span>, </span>`;
                    filterList[i].appendChild(child);
                }
            } else {
                child.innerHTML = activeMarkets[j].title;
                filterList[i].appendChild(child);
            }
        }
        if (activeMarkets.length < 1 && !activeFilters.classList.contains('empty')) {
            filterList[i].classList.add('hidden');
        }
    }
}

function saveMarketSelection() {
    const marketsList = document.getElementById('market-checkboxes');
    $('#save-markets').on('click', (e) => {
        e.preventDefault();
        displayActiveMarkets();
        marketsList.style.display = 'none';
        setActiveMarketsHeight();
    });
}

function toggleMarketCheckboxes() {
    const marketsInput = $('.toggle-markets input');
    const marketsList = $('#market-checkboxes');

    document.body.addEventListener('click', (event) => {
        const checkboxes = document.getElementById('market-checkboxes');
        if (!checkboxes) {
            return;
        }

        if (event.target.id === 'save-markets') {
            saveMarketSelection();
        } else {
            let ww = Math.min(1200, window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 110;
            $(window).resize(() => {
                clearTimeout(window.resizeFinished);
                window.resizeFinished = setTimeout(() => {
                    ww = Math.min(1200, window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 110;
                    marketsList.css({ width: `${ww}px` });
                    setActiveMarketsHeight();
                }, 25);
            });
            if (checkboxes.contains(event.target)) {
                marketsList.css({ display: 'flex', width: `${ww}px` });
            } else if (document.getElementsByClassName('toggle-markets')[0].contains(event.target)) {
                marketsList.css({ display: 'flex', width: `${ww}px` });
            } else {
                displayActiveMarkets();
                marketsList.css({ display: 'none' });
            }
        }
    });

    marketsInput.on('focusin', () => {
        marketsList.css({ display: 'flex' });
    });
}

function fetchImage(url) {
    return new Promise(((resolve, reject) => {
        const image = new Image();
        image.src = url;
        image.onload = resolve;
        image.onerror = reject;
    }));
}

function loadImage(image) {
    const src = image.dataset.src;
    fetchImage(src).then(() => {
        image.src = src;
    });
}

function handleIntersection(entries) {
    entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
            loadImage(entry.target);
        }
    });
}

function resizeCourseNav() {
    const courseList = $('.additional-course-info-block-info .course__nav');
    const video = $('#vimeo_video_id');

    if (courseList && video) {
        courseList.css({ maxHeight: video.outerHeight() });
    }
}

function loadSlider(entries) {
    entries.forEach((entry) => {
        const slideCount = entry.querySelectorAll('.splide__slide').length;

        if (entry.classList.contains('testimonial-slide')) {
            new Splide(entry, {
                autoplay: true,
                arrows: false,
                pagination: false,
                rewind: true,
                perPage: 3,
                gap: '16px',
                type: slideCount > 3 ? 'loop' : 'slide',
                padding: {
                    right: slideCount > 3 ? '10%' : 0,
                },
                breakpoints: {
                    1550: {
                        perPage: 2,
                        pagination: false,
                    },
                    991: {
                        perPage: 1,
                        pagination: true,
                    },
                },
            }).mount();
        } else {
            new Splide(entry, {
                autoplay: true,
                arrows: slideCount > 1,
                pagination: slideCount > 1,
                rewind: true,
            }).mount();
        }
    });
}

function bindLogic() {
    $('.post__title, .courses-list__item-text h5').each(function () {
        // noinspection InnerHTMLJS
        this.innerHTML = this.innerHTML.replace(/®/g, '<sup>®</sup>');
    });

    const images = document.querySelectorAll('.img-responsive');

    const options = {
        // If the image gets within 50px in the Y axis, start the download.
        root: null, // Page as root
        rootMargin: '100px',
        threshold: 0.1,
    };

    if ('IntersectionObserver' in window) {
        // The observer for the images on the page
        const observer = new IntersectionObserver(handleIntersection, options);

        images.forEach((img) => {
            observer.observe(img);
        });
    } else {
        $('.img-responsive').each(function () {
            $(this).attr('src', $(this).attr('data-src'));
        });
    }

    $(':input').inputmask();

    // disable active lang link
    $('.lang .active a').click((e) => {
        e.preventDefault();
    });

    const useFunctionalCookies = getCookie('functional_cookies') && getCookie('functional_cookies') === '1';

    // show infoline
    if (!getCookie('infoline') || !useFunctionalCookies) {
        $('.infoline').fadeIn();
    }

    // close infoline
    $('.infoline .close-x').click(function (e) {
        e.preventDefault();
        $(this).parent('.infoline').fadeOut();
        if (useFunctionalCookies) {
            setCookie('infoline', '1', 1);
        }
    });

    if (!getCookie('cc_subscribe_bar_closed') || !useFunctionalCookies) {
        const $el = $('#news-letter-bar');
        $el.fadeIn();
        $el.find('.newsletter__close').click((e) => {
            e.preventDefault();
            $el.fadeOut();
            if (useFunctionalCookies) {
                setCookie('cc_subscribe_bar_closed', '1', 1);
            }
        });
    }

    // show/hide filters block on mobile
    $('.filter__block h3').click((e) => {
        e.preventDefault();
        $('.filter__block form').slideToggle();
    });

    // show/hide mobile menu
    $('header .mobile-menu, header .mobile-expand a').click((e) => {
        e.preventDefault();
        const header = $('header');
        const doc = $('html, body');
        header.toggleClass('active');
        doc.css({
            overflow: 'auto',
            height: 'auto',
        });
        if (header.hasClass('active')) {
            doc.css({
                overflow: 'hidden',
                height: '100%',
            });
        }
    });

    // show/hide menu block on mobile
    $('.side-menu h3').click((e) => {
        e.preventDefault();
        $('.side-menu ul').slideToggle();
    });

    $(document).scroll(() => {
        // sticky header
        if ($(document).scrollTop() > 200) {
            $('body').addClass('fixed');
        } else {
            $('body').removeClass('fixed');
        }
    });

    $('.datepicker').datepicker({
        format: 'yy-mm-dd',
    });

    tippy('.tippy', {
        theme: 'light',
        arrow: true,
        animation: 'fade',
    });

    $('.faq__title').click(function () {
        const $el = $(this).parent();

        $el.toggleClass('opened');
        $el.find('.faq__content').slideToggle();
    });

    // Quick and dirty
    const pathname = window.location.pathname;
    $('a').each(function () {
        const track = $(this).attr('data-url-track');
        if (track === 'no') {
            return;
        }

        let href = $(this).attr('href');
        if (!href) {
            return;
        }

        if (href.indexOf('http') === 0) {
            if (href.indexOf(window.location.host) >= 0) {
                href = href.substr(href.indexOf(window.location.host) + window.location.host.length);
            } else {
                return;
            }
        }

        if (href.endsWith(pathname)) {
            $(this).addClass('url-current');
        } else if (href.indexOf(pathname) >= 0) {
            $(this).addClass('url-partial');
        }
    });

    // scroll to content
    if ($('body').width() < 992) {
        if (window.location.href.indexOf('date_from') > -1) {
            if ($('#filter-results-content').length > 0) {
                $('html, body').animate({
                    scrollTop: $('#filter-results-content').offset().top - 80,
                }, 250);
            }
        }
    }

    $('#start-learning-header-form').submit((e) => {
        const val = $('#start-learning-header-form :input[name=\'q\']').val();
        if (val.length > 0 && val.length < 3) {
            e.preventDefault();

            const btn = $(document.activeElement)[0];
            const instance = tippy(btn);
            if (instance) {
                instance.show();
            }
        }
    });

    $('#start-learning-form').submit((e) => {
        const val = $('#start-learning-form :input[name=\'q\']').val();
        if (val.length > 0 && val.length < 3) {
            e.preventDefault();

            const btn = $(document.activeElement)[0];
            const instance = tippy(btn);
            if (instance) {
                instance.show();
            }
        }
    });

    let settingSeen = false;
    // courses video navigation
    $('.course__nav .course__nav-item a').click(async (e) => {
        e.preventDefault();

        if ($(e.target).hasClass('active')) {
            vimeoPlay();
            return;
        }

        if (settingSeen) {
            return;
        }
        settingSeen = true;

        let thisId = e.target.hash;
        const thisTitle = e.target.title;
        thisId = `https://player.vimeo.com/video/${thisId.replace('#', '')}`;
        $('.course__video iframe').attr('src', thisId);
        $('.course__video iframe').attr('title', thisTitle);
        $('.course__nav .course__nav-item a').removeClass('active');
        $('.quiz-holder').hide();
        $('#vimeo_video_id').show();
        $('.course__nav-video-quiz a').removeClass(['active-quiz', 'active']);
        e.target.classList.add('active');

        try {
            const thisTranslationID = $(e.target).find('.video_translation_id').html();
            const data = await axios.post('/courses/set-video-seen', { videoTranslationId: thisTranslationID });
            if (data.data.data.set_success) {
                e.target.classList.add('watched');
            }
        } catch (err) {
            console.error(err);
        }

        settingSeen = false;
    });

    $('.course__nav-video-quiz').click((e) => {
        e.preventDefault();
        const thisId = e.target.hash.replace('#', '');
        $('.course__nav-video-quiz a').removeClass(['active-quiz', 'active']);
        $('.course__nav .course__nav-item a').removeClass('active');
        e.target.classList.add('active-quiz', 'active');
        document.dispatchEvent(new CustomEvent('course-quiz', { detail: { id: thisId } }));
    });

    // courses video navigation
    $('.course__tabs a').click((e) => {
        e.preventDefault();
        $('.course__tabs a').removeClass('active');
        $('.course__tabs-info').removeClass('course__tabs-info--active');
        $(e.target.hash).addClass('course__tabs-info--active');
        e.target.classList.add('active');

        loadSlider(Array.from(document.querySelectorAll('.course__reviews .splide')));
    });

    $('[check-all]').each(function () {
        const item = $(this);
        const checkBoxes = $(`[name='${item.attr('check-all')}']`);

        function generateButtonTitle() {
            if (checkBoxes.not(':checked').length >= 1) {
                item.text(item.attr('select-text'));
            } else {
                item.text(item.attr('unselect-text'));
            }
        }

        generateButtonTitle();

        checkBoxes.change(() => {
            generateButtonTitle();
        });

        item.click(e => {
            e.preventDefault();
            if (checkBoxes.not(':checked').length >= 1) {
                checkBoxes.prop('checked', true);
            } else {
                checkBoxes.prop('checked', false);
            }
            generateButtonTitle();
        });
    });

    $('.collapsable-content').each(function () {
        const $el = $(this);
        const $content = $el.find('.collapsable-content__body');
        const $showMore = $el.find('.collapsable-content__show-more');
        const $showLess = $el.find('.collapsable-content__show-less');
        if ($content.height() > $el.height()) {
            $el.find('.collapsable-content__footer').show();
            $el.addClass('collapsable-content--expandable');
            if ($el.hasClass('active')) {
                $showLess.show();
            } else {
                $showMore.show();
            }
        }

        $showMore.find('a').click(e => {
            e.preventDefault();
            $showMore.hide();
            $showLess.show();
            $el.toggleClass('active');
        });

        $showLess.find('a').click(e => {
            e.preventDefault();
            $showMore.show();
            $showLess.hide();
            $el.toggleClass('active');
        });
    });

    loadSlider(Array.from(document.querySelectorAll('.splide')));
}

const $filterCheckboxes = $('.filter .checkboxes.checkboxes--collapsed');
if ($filterCheckboxes.length) {
    const calculateItemsHeight = ($items, count) => {
        let height = 0;
        for (let i = 0; i < count; i++) {
            height += $items.eq(i).outerHeight(true);
        }
        if ($('body').width() < 992) {
            // hack for mobile
            if (height > 130) {
                height += 5;
            } else {
                height = 150;
            }
        }
        return height;
    };

    $(document).on('click', '.filter-show-more', (e) => {
        e.preventDefault();
        const $btn = $(e.currentTarget);
        const $el = $btn.parent().find('.checkboxes');
        const $items = $el.children();
        if ($btn.hasClass('active')) {
            $el.animate({
                height: calculateItemsHeight($items, 5),
            }, () => {
                $btn.removeClass('active');
                $btn.find('.filter-show-less__text').hide();
                $btn.find('.filter-show-more__text').show();
            });
        } else {
            $el.animate({
                height: $el.get(0).scrollHeight,
            }, () => {
                $btn.addClass('active');
                $btn.find('.filter-show-less__text').show();
                $btn.find('.filter-show-more__text').hide();
            });
        }
    });

    $filterCheckboxes.each(function () {
        const $list = $(this);
        const $items = $list.children();
        if ($items.length > 5) {
            $list.css({ height: calculateItemsHeight($items, 5) });
            $list.parent().find('.filter-show-more').show();
            $list.parent().find('.filter-show-more .filter-show-more__text').show();
        }
    });
}


function bindModals() {
    $('[open-modal]').each(function () {
        const $el = $(this);
        const eventName = `modal-open:${$el.attr('open-modal')}`;
        let eventData = $el.data('modal');
        eventData = eventData || {};
        const event = new CustomEvent(eventName, { detail: eventData });

        $el.click((e) => {
            e.preventDefault();
            document.dispatchEvent(event);
        });
    });

    if (window.location.hash) {
        const hash = window.location.hash.substring(1);
        if (hash.search('modal-open=') > -1) {
            const eventName = `modal-open:${hash.replace('modal-open=', '')}`;
            const event = new CustomEvent(eventName);
            document.dispatchEvent(event);
        }
    }

    const authenticatedEvent = () => {
        window.location.reload();
    };

    $('[open-auth-modal]').click((e) => {
        e.preventDefault();
        document.removeEventListener('authenticated', authenticatedEvent);
        document.dispatchEvent(new Event('modal-open:auth-modal'));
        document.addEventListener('authenticated', authenticatedEvent);
    });
}

function videoModal() {
    $('#playVideo').on('hidden.bs.modal', () => {
        $('#playVideo #modalVideo').attr('src', $('#playVideo #modalVideo').attr('src'));
    });
}

function togglePanelItems() {
    const btns = document.getElementsByClassName('toggle-expand');
    for (let i = 0; i < btns.length; i++) {
        btns[i].addEventListener('click', (e) => {
            e.preventDefault();
            btns[i].classList.toggle('open');
            const parentElement = btns[i].parentElement;
            const subtractedElements = parentElement.querySelectorAll('.subtract-on-mobile');
            const expandedElements = parentElement.querySelectorAll('.expanded');

            for (let k = 0; k < expandedElements.length; k++) {
                expandedElements[k].classList.replace('expanded', 'subtract-on-mobile');
            }

            for (let j = 0; j < subtractedElements.length; j++) {
                subtractedElements[j].classList.replace('subtract-on-mobile', 'expanded');
            }
        });
    }
}

function bindCopyToClipboard() {
    $('.copy-to-clipboard').each(function () {
        const $el = $(this);
        $el.click((e) => {
            e.preventDefault();
            const $temp = $('<input>');
            $('body').append($temp);
            $temp.val($el.attr('href')).select();
            document.execCommand('copy');
            $temp.remove();
        });
    });
}

function activeFilters(checkboxesSelector, labelSelector = null, list = true, price = false, date = false) {
    const elements = [];
    const selector = document.querySelector(checkboxesSelector);
    let inputs;
    if (selector) {
        inputs = selector.querySelectorAll('input');
    }

    if (!inputs) {
        return;
    }

    if (price || date) {
        for (let k = 0; k < inputs.length; k++) {
            if (inputs[k].value) {
                elements.push({
                    id: inputs[k].value,
                    title: `${inputs[k].placeholder} - ${inputs[k].value}`,
                });
            }
        }
    } else {
        for (let i = 0; i < inputs.length; i++) {
            let labelTitle = document.querySelector(`label[for="filter_${labelSelector}"]`);
            let rawTitle;
            if (labelTitle) {
                rawTitle = labelTitle.textContent;
            }
            if (list) {
                labelTitle = document.querySelector(`label[for="filter_${labelSelector}_${inputs[i].value}"]`);
                rawTitle = labelTitle.textContent;
                if (checkboxesSelector === '#market-checkboxes') {
                    rawTitle = rawTitle.replace(labelTitle.querySelector('.hidden').textContent, '');
                }
            }
            if (inputs[i].checked) {
                elements.push({
                    id: inputs[i].value,
                    title: rawTitle,
                });
            }
        }
    }

    let filter;
    if (list) {
        filter = $(`#filter-${labelSelector}`);
    } else if (!list && price) {
        filter = $('#filter-price');
    } else if (!list && !price && date) {
        filter = $('#filter-date');
    } else {
        filter = $('#filter-other');
    }

    if (elements.length > 0) {
        filter.removeClass('hidden');

        for (let j = 0; j < elements.length; j++) {
            if (elements.length > 1) {
                if (j === elements.length - 1) {
                    filter.append(`<span>${elements[j].title}</span>`);
                } else {
                    filter.append(`<span>${elements[j].title}</span><span>, </span> `);
                }
            } else {
                filter.append(`<span>${elements[j].title}</span>`);
            }
        }

        if (!list && filter.children('span').length > 1 && !price && !date) {
            filter.find('span:first-child').after(', ');
        }
    }
}

function displayActiveFilters() {
    activeFilters('#languages-checkboxes', 'lang');
    activeFilters('#market-checkboxes', 'market');
    activeFilters('#levels-checkboxes', 'level');
    activeFilters('#types-checkboxes', 'type');
    activeFilters('#certificate-checkbox', 'certificate', false);
    activeFilters('#unlocked-checkbox', 'unlocked', false);
    activeFilters('#price-checkbox', null, false, true);
    activeFilters('#date-checkbox', null, false, false, true);
}

function pushToActiveMarkets() {
    const markets = document.getElementsByClassName('flt_market');
    if (markets) {
        for (let i = 0; i < markets.length; i++) {
            if (markets[i].checked === true) {
                const labelTitle = document.querySelector(`label[for="filter_market_${markets[i].value}"]`);
                let rawTitle;
                if (labelTitle) {
                    rawTitle = labelTitle.innerText;
                    rawTitle = rawTitle.replace(labelTitle.querySelector('.hidden').innerText, '');
                }
                activeMarkets.push({
                    id: markets[i].value,
                    title: rawTitle,
                });
            }
            // eslint-disable-next-line no-loop-func
            markets[i].addEventListener('change', () => {
                if (markets[i].checked === true) {
                    const labelTitle = document.querySelector(`label[for="filter_market_${markets[i].value}"]`);
                    activeMarkets.push({
                        id: markets[i].value,
                        title: labelTitle.innerText,
                    });
                } else {
                    activeMarkets = activeMarkets.filter((obj) => obj.id !== markets[i].value);
                }
            });
        }
    }
}

function checkResultsCount() {
    const counter = $('#resultCounter');
    if (counter.data('count') === 0) {
        $('.wrapper').css({ overflow: 'auto' });
    }
}

function scrollOnSearch() {
    window.addEventListener('load', () => {
        const activeFilterBar = $('.active_filters:not(.hidden)');
        if ((window.location.href.indexOf('search?') > -1 || window.location.href.indexOf('topic=') > -1) && activeFilterBar.length > 0) {
            $('html, body').animate({
                scrollTop: activeFilterBar.offset().top - 70,
            }, 350);
        }
    });
}

function bindLimitVisible() {
    $('[data-limit-visible]').each(function () {
        const $el = $(this);
        const limit = $el.attr('data-limit-visible');
        const $body = $el.find('.limit-visible__body').first();
        let visible = false;
        const $showButton = $el.find('.limit-visible__show-more');
        const $hideButton = $el.find('.limit-visible__show-less');
        const $elements = $body.children();
        if ($elements.length <= limit) {
            $hideButton.hide();
            $showButton.hide();
            return;
        }

        const toggleVisibility = function () {
            if (visible) {
                $hideButton.show();
                $showButton.hide();
            } else {
                $hideButton.hide();
                $showButton.show();
            }

            $elements.each(function (index) {
                const $child = $(this);
                if (index + 1 > limit) {
                    if (visible) {
                        $child.show();
                    } else {
                        $child.hide();
                    }
                }
            });
        };

        toggleVisibility();

        $showButton.click(() => {
            visible = true;
            toggleVisibility();
        });

        $hideButton.click(() => {
            visible = false;
            toggleVisibility();
        });
    });
}

Vue.directive('focus', require('./directives/focus').default);
Vue.directive('mount', require('./directives/mount').default);
Vue.directive('inserted', require('./directives/inserted').default);
Vue.directive('removed', require('./directives/removed').default);
Vue.directive('dropdown', require('./directives/dropdown').default);
Vue.directive('scroll-end', require('./directives/scroll-end').default);

Vue.component('v-form', require('./form/Form').default);
Vue.component('v-form-group', require('./form/FormGroup').default);
Vue.component('v-checkbox', require('./form/FormGroup').default);
Vue.component('v-form-error', require('./form/FormError').default);
Vue.component('cc-instructor-form', require('./components/InstructorForm').default);
Vue.component('cc-mailerlite-form', require('./components/MailerLiteForm').default);
Vue.component('cc-auth-form', require('./components/AuthForm').default);
Vue.component('cc-change-password-form', require('./components/profile/ChangePasswordForm').default);
Vue.component('cc-autocomplete-input', require('./form/AutocompleteInput').default);
Vue.component('cc-cart-form', require('./components/CartForm').default);
Vue.component('cc-modal', require('./components/Modal').default);
Vue.component('cc-checkout-form', require('./components/CheckoutForm').default);
Vue.component('cc-user-info', require('./components/profile/UserInfo').default);
Vue.component('cc-button', require('./form/Button').default);
Vue.component('cc-preferences-form', require('./components/profile/PreferencesForm').default);
Vue.component('cc-report-form', require('./components/ReportForm').default);
Vue.component('cc-pdf', require('./components/Pdf').default);
Vue.component('cc-notification-indicator', require('./components/NotificationIndicator').default);
Vue.component('cc-tippy', require('./components/Tippy').default);
Vue.component('cc-delete-account-form', require('./components/DeleteAccountForm').default);
Vue.component('cc-select', require('./form/Select').default);
Vue.component('cc-dropdown', require('./form/dropdown/Dropdown').default);
Vue.component('cc-unsubscribe-form', require('./components/newsletter-subscribtions/UnsubscribeForm').default);
Vue.component('cc-unlock-form', require('./components/UnlockForm').default);
Vue.component('cc-login-or-register', require('./components/LoginOrRegister').default);
Vue.component('cc-order-summary', require('./components/OrderSummary').default);
Vue.component('cc-quiz-form', require('./components/QuizForm').default);
Vue.component('cc-comments', require('./components/Comments').default);
Vue.component('cc-comment', require('./components/Comment').default);
Vue.component('cc-invitation-form', require('./components/profile/business-account/InvitationForm').default);
Vue.component('cc-license-purchase-subscription-form', require('./components/profile/business-account/LicensePurchaseSubscriptionForm').default);
Vue.component('cc-license-purchase-course-form', require('./components/profile/business-account/LicensePurchaseCourseForm').default);
Vue.component('cc-license-grant-access-form', require('./components/profile/business-account/LicenseGrantAccessForm').default);
Vue.component('cc-contact-us-form', require('./components/ContactUsForm').default);
Vue.component('cc-register-event', require('./components/EventRegistration').default);

Vue.filter('currency', require('./filters/currency').default);

Vue.use(Toasted);

new Vue({
    store: Store,
    mounted() {
        bindLogic();
        bindModals();
        bindCopyToClipboard();
        bindLimitVisible();
        initVimeoPlay();
        toggleFilter();
        toggleMarketCheckboxes();
        searchMarketCheckboxes();
        eraseInput();
        displayActiveFilters();
        resetActiveFilter();
        setBannerHeight();
        togglePanelItems();
        videoModal();
        pushToActiveMarkets();
        saveMarketSelection();
        checkResultsCount();
        scrollOnSearch();
        resizeCourseNav();
        Store.commit('getCart');
    },
}).$mount('#app');

$(document).ready(() => {
    require('./cookie-consent');
    $('html').removeClass('loading');
});

$(window).on('beforeunload', (e) => {
    if (!e.target.activeElement.hasAttribute('bypass-loader')) {
        $('html').addClass('loading');
    }
});

$(window).resize(() => {
    if ($(window).width() >= 991) {
        setTimeout(resizeCourseNav, 50);
    }
});
