var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cc-select",
      class: {
        "cc-select--focused": _vm.isOptionsVisible,
        "display-x": _vm.displayX
      }
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue"
          }
        ],
        ref: "input",
        staticClass: "flt_topic",
        attrs: {
          name: _vm.name,
          placeholder: _vm.placeholder,
          autocomplete: "off",
          type: "text",
          "data-clear": "0"
        },
        domProps: { value: _vm.inputValue },
        on: {
          focus: _vm.showOptions,
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.inputValue = $event.target.value
            },
            _vm.onInput
          ],
          keydown: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown"
                ])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.goDown($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.selectHighlightedOption($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.goUp($event)
            }
          ]
        }
      }),
      _vm._v(" "),
      _c("a", {
        staticClass: "remove_input_value",
        attrs: { href: "", id: "remove_topics" },
        on: {
          click: function($event) {
            return _vm.eraseInput($event)
          }
        }
      }),
      _vm._v(" "),
      _vm.isOptionsVisible
        ? _c(
            "ul",
            { ref: "dropdown", staticClass: "cc-select__options" },
            _vm._l(_vm.inputOptions, function(option) {
              return _c("li", {
                class: {
                  "cc-select__option--hovered":
                    option === _vm.highlightedOption,
                  "cc-select__option--selected": _vm.value === option
                },
                domProps: { innerHTML: _vm._s(option) },
                on: {
                  click: function($event) {
                    return _vm.selectOption(option)
                  },
                  mouseover: _vm.optionHovered
                }
              })
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }