<template>
    <div :class="{'cc-select--focused': isOptionsVisible, 'display-x':displayX}" class="cc-select">
        <input
            :name="name"
            :placeholder="placeholder"
            @focus="showOptions"
            @input="onInput"
            @keydown.down.prevent="goDown"
            @keydown.enter.prevent="selectHighlightedOption"
            @keydown.up.prevent="goUp"
            autocomplete="off"
            ref="input"
            class="flt_topic"
            type="text"
            v-model="inputValue"
            data-clear="0"
        >
        <a
            href=""
            class="remove_input_value"
            id="remove_topics"
            @click="eraseInput($event)"
        >
        </a>
        <ul class="cc-select__options" ref="dropdown" v-if="isOptionsVisible">
            <li
                :class="{
                    'cc-select__option--hovered': option === highlightedOption,
                    'cc-select__option--selected': value === option,
                }"
                @click="selectOption(option)"
                @mouseover="optionHovered"
                v-for="option in inputOptions"
                v-html="option"
            >
            </li>
        </ul>
    </div>
</template>

<script>
    import throttle from 'lodash/throttle';

    const documentMaxWidth = 1420;
    const documentInset = 110;

    export default {
        name: 'cc-select',

        props: {
            options: {
                type: Array,
                default() {
                    return [];
                },
            },
            placeholder: {
                type: String,
                required: false,
            },

            value: {
                type: String,
                required: false,
            },

            name: {
                type: String,
                required: false,
            },

            formId: {
                type: String,
                required: false,
            },
        },

        data() {
            return {
                inputValue: '',
                isOptionsVisible: false,
                displayX: false,
                inputOptions: this.options,
                timer: null,
                highlightedOption: null,
                resizeWatcher: null,
                letters: [],
                initialized: false,
            };
        },

        computed: {
            formToSubmit() {
                if (!this.formId) {
                    return null;
                }

                const form = document.getElementById(this.formId);
                return form ? form : null;
            },
        },

        watch: {
            isOptionsVisible(value) {
                if (!value) {
                    window.removeEventListener('resize', this.resizeWatcher);
                    this.resizeWatcher = null;
                    return;
                }

                this.$nextTick(() => {
                    const listener = () => {
                        if (!this.$refs.dropdown) {
                            return;
                        }

                        const ww = Math.min(documentMaxWidth, window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - documentInset;
                        this.$refs.dropdown.style.width = `${ww}px`;
                    };

                    this.resizeWatcher = throttle(listener, 100);
                    listener();
                    window.addEventListener('resize', this.resizeWatcher);
                });
            },
        },

        mounted() {
            this.inputValue = this.value;
            document.addEventListener('click', (e) => {
                let path = [];

                if ('path' in Event.prototype) {
                    path = e.path;
                } else {
                    let currentElem = e.target;
                    while (currentElem) {
                        path.push(currentElem);
                        currentElem = currentElem.parentElement;
                    }
                    if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
                        path.push(document);
                    }
                    if (path.indexOf(window) === -1) {
                        path.push(window);
                    }
                }

                if (path.indexOf(this.$el) < 0) {
                    this.hideOptions();
                }
            });
        },

        methods: {
            initLetters() {
                for (let i = 0; i < this.inputOptions.length; i++) {
                    if (this.letters.indexOf(this.inputOptions[i].charAt(0).toUpperCase()) === -1) {
                        this.letters.push(this.inputOptions[i].charAt(0).toUpperCase());
                        let option = `<span>${this.inputOptions[i].charAt(0).toUpperCase()}</span> ${this.inputOptions[i]}`;
                        option = option.replace(/<\/?(?!span)\w*\b[^>]*>/ig, '');
                        this.inputOptions[i] = option;
                    }
                }
                this.initialized = true;
            },
            eraseInput(e) {
                e.preventDefault();
                this.displayX = false;
                this.inputOptions = this.options;
                this.inputValue = '';
            },
            showOptions() {
                this.isOptionsVisible = true;
                if (!this.initialized) {
                    this.initLetters();
                }
                if (this.$el.querySelector('input').getAttribute('data-clear') === '1') {
                    this.displayX = false;
                    this.inputOptions = this.options;
                    this.inputValue = '';
                }
            },

            hideOptions() {
                this.isOptionsVisible = false;
            },

            selectOption(option) {
                let val = option;
                if (option.indexOf('<span>') === 0) {
                    val = option.substring(option.indexOf('</span>') + 8);
                }
                this.$emit('input', val);
                this.inputValue = val;
                this.hideOptions();
                this.$refs.input.blur();
                this.onInput();
                if (this.formToSubmit) {
                    this.$nextTick(() => {
                        this.formToSubmit.submit();
                    });
                }
            },

            onInput() {
                this.highlightedOption = null;
                this.displayX = true;

                if (!this.inputValue) {
                    this.inputOptions = this.options;
                    this.displayX = false;
                    return;
                }

                this.inputOptions = this.options.filter(item => {
                    const itemValue = item.toLowerCase();
                    const inputValue = this.inputValue.toLowerCase();

                    return itemValue !== inputValue && itemValue.indexOf(inputValue) > -1;
                });
            },

            goUp() {
                let index = this.inputOptions.indexOf(this.highlightedOption);
                if (index < 1) {
                    index = this.inputOptions.length;
                }

                this.highlightedOption = this.inputOptions[index - 1];
            },

            goDown() {
                let index = this.inputOptions.indexOf(this.highlightedOption);
                if (index >= this.inputOptions.length - 1) {
                    index = -1;
                }

                this.highlightedOption = this.inputOptions[index + 1];
            },

            selectHighlightedOption() {
                if (!this.highlightedOption) {
                    return;
                }

                this.selectOption(this.highlightedOption);
            },

            optionHovered() {
                this.highlightedOption = null;
            },
        },
    };
</script>
